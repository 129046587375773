<template>
  <div class="bg app_page">
    <!-- Navbar -->
    <app-navbar />
    <!-- Banner -->
    <div class="banner-wrapper">
      <div class="banner-offset">
        <div class="banner-body text-center p-2">
          <h1 data-testid="guest-header" class="text-xl font-bold mt-4 w-full">
            {{ $t("guest.header") }}
          </h1>
          <!-- If authenticating, show loading -->
          <div v-if="!loading">
            <div
              class="
                mt-6
                flex flex-wrap
                justify-center justify-around
                w-11/12
                mx-auto
                card-list
              "
            >
              <!-- User Card -->
              <div class="button-card w-full" v-on:click="userLoginClicked">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="mx-auto"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
                <h2
                  data-testid="user-card"
                  class="text-xl font-semibold w-full"
                >
                  {{ $t("guest.user") }}
                </h2>
                <p>{{ $t("guest.click_here_user_1") }}</p>
                <p>{{ $t("guest.click_here_user_2") }}</p>
              </div>

              <!-- Student Card -->
              <div
                class="sammen-button-card w-full"
                v-on:click="studentLoginClicked"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="mx-auto"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
                <h2
                  data-testid="student-card"
                  class="text-xl font-semibold w-full"
                >
                  {{ $t("guest.student") }}
                </h2>
                <div class="flex flex-wrap justify-center flex-row">
                  <p>
                    {{ $t("guest.click_here_student_1") }}
                  </p>
                  <span class="sammen">Sammen</span>
                  <p>
                    {{ $t("guest.click_here_student_2") }}
                  </p>
                </div>
              </div>

              <!-- Feide Card -->
              <div
                class="button-card feide-button-card w-full"
                v-on:click="feideLoginClicked"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 -1 32 42"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="mx-auto"
                >
                  <rect
                    class="cls-1"
                    x="27.99"
                    y="20.36344"
                    width="4.24"
                    height="10.18"
                    id="svg_1"
                    fill="#ffffff"
                  />
                  <polygon
                    class="cls-1"
                    points="18.229999542236328,23.333436965942383 13.989999771118164,23.333436965942383 13.989999771118164,34.7834358215332 4.239999771118164,34.7834358215332 4.239999771118164,20.36343765258789 0,20.36343765258789 0,35.63343811035156 0.019999999552965164,35.63343811035156 0.019999999552965164,39.0234375 32.22999954223633,39.0234375 32.22999954223633,34.7834358215332 18.229999542236328,34.7834358215332 18.229999542236328,23.333436965942383 "
                    id="svg_2"
                    fill="#ffffff"
                  />
                  <circle
                    class="cls-1"
                    cx="16.11"
                    cy="16.55344"
                    r="2.54"
                    id="svg_3"
                    fill="#ffffff"
                  />
                  <path
                    class="cls-1"
                    d="m16.11,4.26344a11.87,11.87 0 0 1 11.89,11.86l4.25,0a16.11,16.11 0 0 0 -32.25,0l4.25,0a11.87,11.87 0 0 1 11.86,-11.86z"
                    id="svg_4"
                    fill="#ffffff"
                  />
                </svg>
                <h2
                  data-testid="feide-card"
                  class="text-xl font-semibold w-full"
                >
                  {{ $t("guest.feide") }}
                </h2>
                <p>{{ $t("guest.click_here_feide_1") }}</p>
                <p>{{ $t("guest.click_here_feide_2") }}</p>
              </div>
            </div>

            <!-- Forgot password -->
            <div
              class="
                flex flex-row
                justify-center
                self-center
                cursor-pointer
                mt-4
              "
            >
              <p
                data-testid="reset-password"
                v-on:click="resetPasswordClicked"
                class="hover:underline"
              >
                {{ $t("guest.forgot_password") }}
              </p>
            </div>
          </div>
          <div class="flex justify-center h-48" v-else>
            <el-spinner class="self-center"></el-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validateToken } from "../api/index";
import AppNavbar from "@/components/app/AppNavbarGuest";
import jwtDecode from "jwt-decode";

export default {
  components: {
    AppNavbar,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("oidcStore", ["oidcAccessToken"]),
  },
  methods: {
    validateToken: async function () {
      if (this.oidcAccessToken !== null) {
        if ((await validateToken()) === 200) {
          this.loading = false;
          if (this.isOnlyInstructor()) {
            this.$router.push({ name: "courseevent-index" });
            if (this.$router.currentRoute.name !== 'courseevent-index') {
              this.$router.push({ name: "courseevent-index" });
            }
          } else {
            if (this.$router.currentRoute.name !== 'my-courses-index') {
              this.$router.push({name: "my-courses-index"});
            }
          }
        }
      }
      this.loading = false;
    },
    userLoginClicked: function () {
      this.$router.push({ name: "my-courses-index" });
    },
    studentLoginClicked: function () {
      this.$router.push({ name: "studentlogin" });
    },
    feideLoginClicked: function () {
      this.$router.push({ name: "feidelogin" });
    },
    resetPasswordClicked: function () {
      this.$router.push({ name: "reset-password" });
    },
    isOnlyInstructor: function() {
      const roles = jwtDecode(this.oidcAccessToken).role;
      
      if (typeof roles !== 'string') {
        return roles.every(r => r === 'Instructor' || r === 'InstructorAdmin');
      } else {
        return (roles === 'Instructor' || roles === 'InstructorAdmin');
      }
    }
  },
  mounted() {
    this.loading = true;
    setTimeout(() => {
      this.validateToken();
    }, 2000);
  },
  updated() {
    setTimeout(() => {
      this.validateToken();
    }, 2000);
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");

.app_page {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bg {
  background-color: #f2f2f2;
}

.banner-wrapper {
  display: flex;
  height: 29em;
  width: 100%;
  background-image: url("../../../assets/images/background.jpg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 50%;
}
.banner-offset {
  display: flex;
  width: 100%;
  align-content: center;
  justify-content: center;
  margin-top: 10em;
  background: linear-gradient(to bottom, transparent 50%, #f2f2f2 0);
}

.banner-body {
  width: 45em;
  align-content: center;
  justify-content: center;
  background-color: white;
  border-radius: 5px;
}

/* Card styling */

.button-card,
.sammen-button-card {
  height: auto;
  min-height: 140px;
  max-width: 13rem;
  padding: 1rem;
  transition: transform 0.2s; /* Animation */
  border: 1px solid black !important;
  border-radius: 5px !important;
  color: black !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button-card:hover {
  cursor: pointer;
  transform: scale(1.1);
  background-color: #e20011 !important;
  border-color: #b3000f;
  color: white !important;
}

.sammen-button-card:hover {
  cursor: pointer;
  transform: scale(1.1);
  background-color: #ff6700 !important;
  border-color: #cc5200;
  color: white !important;
}

.sammen-button-card:hover .sammen {
  color: white;
}

.feide-button-card:hover {
  cursor: pointer;
  transform: scale(1.1);
  background-color: #1f4698 !important;
  border-color: #cc5200;
  color: white !important;
}

.sammen {
  padding-right: 4px;
  color: #ff6700;
  font-family: "Montserrat", sans-serif;
  margin-top: 1px;
}

@media only screen and (max-width: 1023px) {
  .banner-wrapper {
    height: 100%;
    background-size: 100% 10em;
    background-position: initial;
  }

  .card-list {
    gap: 0.5rem;
    justify-content: start;
  }

  .button-card,
  .sammen-button-card {
    max-width: 48%;
  }
}
</style>
